<template>
  <div v-if="loading" v-shilp-loader="true" class="p-7"></div>
  <div v-else :class="classes">
    <div v-if="errors" class="p-3 mb-3">
      <error :data="errors" />
    </div>
    <slot v-else :res="res" />
  </div>
</template>

<script>
export default {
  props: {
    get: Function,
    params: Object,
    breadcrumb: [Boolean, String],
    breadcrumbRoute: String,
    classes: { type: String, default: "" }
  },
  data() {
    return {
      errors: null,
      loading: false,
      res: null
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    async refresh() {
      if (this.get) {
        this.loading = true;
        this.errors = null;
        try {
          if (this.params) {
            this.res = await this.get(this.params);
          } else {
            this.res = await this.get();
          }
          if (this.breadcrumb) {
            this.$root.$emit(
              "shilp-breadcrumbs-label",
              this.breadcrumbRoute || this.$route.name,
              this.res?.[this.breadcrumb]
            );
          }
        } catch (err) {
          this.errors = err;
        }
        this.loading = false;
      }
    }
  }
};
</script>
