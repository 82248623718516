import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const router = new Router({
    routes: [{
        path: "/",
        component: require("@/views/Auth").default,
        redirect: { name: "dashboard" },
        meta: {
            public: false,
        },
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: require("@/views/dashboard/Index").default,
                meta: {
                    label: "Dashboard",
                    breadcrumbs: ["dashboard"],
                    icon: "vmdi-view-dashboard",
                }
            },
        ]
    },
    {
        path: "/login",
        name: "login",
        component: require("@/views/Login").default,
        meta: {
            public: true
        }
    },
    {
        path: "/logout",
        name: "logout",
        component: require("@/views/Logout").default,
        meta: {
            public: true
        }
    },
    {
        path: "/signup",
        name: "signup",
        component: require("@/views/Signup").default,
        meta: {
            public: true
        }
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: require("@/views/auth/ForgotPassword").default,
        meta: {
            public: true
        }
    }
    ]
});
export default router;

router.beforeEach((from, to, next) => {
    if (from.meta.public) {
        next();
    } else {
        if (localStorage.getItem("pt-token")) {
            next();
        } else {
            next({ name: "login" });
        }
    }
});