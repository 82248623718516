<template>
  <p>Logging out...</p>
</template>

<script>
export default {
  beforeCreate() {
    localStorage.removeItem("pt-token");
    this.$router.push({ name: "login" });
  },
};
</script>
