import axios from "@/plugins/axios";

export const login = (data) => {
  return axios.post("v1/login", data).then((res) => res.data);
};

export const forgot = (data) => {
  return axios.post("password/email", data).then((res) => res.data);
};

export const reset = (data) => {
  return axios.post("password/reset", data).then((res) => res.data);
};

export const resendVerificationEmail = () => {
  return axios.post("/v1/resend-verification-mail");
};

export const resetPassword = (data) => {
  return axios.post("v1/change-password", data).then(res => res.data);
};

export const getProfile = () => {
  return axios.get("v1/me").then(res => res.data);
};


export const saveXML = (data) => {
  return axios.post("v1/xmls", data).then(res => res.data);
};

export const deleteXML = (data) => {
  return axios.delete(`v1/xmls/${data.id}`).then(res => res.data);
};

export const getXML = (data) => {
  return axios.get(`v1/xmls/${data.id}`).then(res => res.data);
};

export const downloadCSV = (data,currentId) => {
  return axios.post(`v1/xmls/csv/${currentId}`,data).then(res => res.data);
};

export const getXMLFeild = (data) => {
  return axios.get(`v1/xmls/fields`).then(res => res.data);
};