import axios from "./axios";
import qs from "qs";

let searchQuery = null;
export default {
    requestHandler(payload) {
        const {
            method,
            endpoint,
            params,
            filters,
            search,
            pagination,
            sort,
        } = payload;
        if (searchQuery != search) {
            pagination.page = 1
            searchQuery = search;
        } 
        return axios[method](endpoint, {
                params: {
                    ...params,
                    search,
                    filter: filters,
                    page: pagination.page,
                    per_page: pagination.perPage,
                    sortBy: sort.by,
                    sortOrder: sort.order,
                },
                paramsSerializer: (params) => qs.stringify(params),
            })
            .then((res) => {
                return res.data;
            })
            .then((res) => {
                return {
                    items: res.data,
                    count: res.meta.total,
                };
            });
    },
};