<template>
  <s-modal id="file-details-modal" title="File Details" overlay="dark">
    <div class="flex bg-light flex--column flex--nowrap h-100 radius-4">
      <div class="flex__fit bg-light bb-lightest px-3 py-2">
        <div class="flex flex--middle">
          <h4 class="flex__fluid">File Details</h4>
          <div class="flex__fit">
            <s-button
              @click.native="closeModal()"
              size="sm"
              shape="circle"
              theme="muted"
              color="grey"
              icon="vmdi-close"
            ></s-button>
          </div>
        </div>
      </div>
      <div class="content flex__fluid bg-light p-3">
        <request :get="get" #default="{ res }" ref="xmlDetails">
          <s-field-validate label="Name" class="mb-3">
            {{ res.name }}
          </s-field-validate>
          <s-field-validate label="Author" class="mb-3">
            {{ res.author && res.author.name }}
          </s-field-validate>
          <s-field-validate label="XML File" class="mb-3">
            <a
              v-if="res.path"
              :href="res.path"
              target="_blank"
              class="text-primary text-bold"
              >File</a
            >
          </s-field-validate>
          <s-field-validate label="CSV File" class="mb-3">
            <a
              v-if="res.csv_path"
              :href="res.csv_path"
              target="_blank"
              class="text-primary text-bold"
              >File</a
            >
          </s-field-validate>
          <s-field-validate label="Created at" class="mb-2">
            {{
              res.created_at && format(new Date(res.created_at), "dd/MM/yyyy")
            }}
          </s-field-validate>
        </request>
      </div>
    </div>
  </s-modal>
</template>
<script>
import { getXML } from "@/api/auth";
import format from "date-fns/format";

export default {
  props: {
    currentFile: Object,
  },
  components: {
    Request: require("@/components/Request").default,
  },
  data() {
    return {
      format,
    };
  },
  methods: {
    closeModal() {
      this.$root.$emit("shilp-modal-close", "file-details-modal");
    },
    get() {
      if (!this.currentFile?.id) {
        return;
      }
      return getXML(this.currentFile).then((res) => res);
    },
  },
};
</script>
