<template>
  <auth image="signup" :error="error">
    <s-alert
      v-if="success"
      color="success"
      theme="muted"
      fluid
      class="mb-3 gx-2 gy-1"
      :title="$t('account_created')"
      icon="vmdi-check-circle"
    >
      <p>
        You can
        <router-link :to="{ name: 'login' }">Login now.</router-link>
      </p>
    </s-alert>
    <validation-observer v-else v-slot="{ invalid }">
      <form @submit.prevent="signup()" class="field-group g-2">
        <s-field-validate label="Name" rules="required" before-icon="Account">
          <s-textbox
            ref="name"
            v-model="form.name"
            type="text"
            placeholder="John Doe"
          />
        </s-field-validate>
        <s-field-validate
          label="Email"
          rules="required|email"
          before-icon="vmdi-at"
        >
          <s-textbox
            v-model="form.email"
            type="email"
            placeholder="john.doe@email.com"
          />
        </s-field-validate>
        <s-field-validate
          label="Password"
          rules="required"
          before-icon="vmdi-lock"
          :password-toggle="true"
        >
          <s-textbox v-model="form.password" type="password" placeholder="" />
        </s-field-validate>
        <s-button
          class="mt-2"
          :disabled="invalid || success"
          :loader="loading"
          type="submit"
          align="right"
          icon="vmdi-check-circle"
          color="primary"
          size="lg"
          fluid
          label="Create Account"
        />
      </form>
    </validation-observer>

    <div slot="footer" class="mt-3 text-light">
      <p>
        Already have an account?
        <router-link class="text-light" to="/">Login</router-link>
      </p>
    </div>
  </auth>
</template>

<script>
import { signup } from "@/api/auth";
import { setToken } from "@/plugins/axios";

export default {
  components: {
    Auth: require("@/components/Auth").default,
  },

  data() {
    return {
      success: false,
      error: null,
      loading: false,
      form: {
        name: null,
        email: null,
        password: null,
      },
    };
  },

  mounted() {
    this.$refs.name.$el.focus();
  },

  methods: {
    async signup() {
      this.error = null;
      this.loading = true;
      signup(this.form)
        .then((res) => {
          localStorage.setItem("pt-token", res.token);
          setToken(res.token);
          this.$shilp.notify({
            type: "success",
            title: "Account Created!",
            message: "We just sent you an email to verify your account.",
          });
          this.$router.push({ name: "campaigns" });
        })
        .catch((err) => {
          console.error(err);
          this.error = err.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
