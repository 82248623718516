import axios from "axios";
import router from "@/router";
import bus from "@utils/vueBus";

// DEFAULT CONFIG
const config = {
  baseURL: process.env.VUE_APP_API,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
  },
};

// INSTANCE
const $axios = axios.create(config);

// SET TOKEN
// Allows to set the token of axios instance after login
const setToken = (token) => {
  $axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

if (localStorage.getItem("pt-token")) {
  setToken(localStorage.getItem("pt-token"));
}

// INTERCEPTORS
$axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Logout if user is no longer authenticated
    // or the token is expired!
    if (error.response && error.response.status === 401) {
      router.replace("/logout");
    }
    //If status is not provided, axios is not sure what happend!
    if (!error.response && !error.status) {
      bus.$emit("network-error");
      return Promise.reject(error);
    } else {
      return Promise.reject(error.response);
    }
  }
);

export default $axios;
export { setToken };
