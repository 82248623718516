<template>
  <div
    class="sidebar flex flex--nowrap bg-primary"
    :class="{ 'sidebar--change': collapse }"
  >
    <s-button-group
      shape="square"
      align="left"
      class="flex__fit mt-4 toggle-menu"
      size="lg"
    >
      <s-button icon="vmdi-menu" @click.native="$emit('isMenu', !isMenu)" />
    </s-button-group>
    <div class="logo py-3 px-4">
      <router-link :to="{ name: 'dashboard' }">
        <div>
          <img
            height="60px"
            position="left"
            src="@/img/logo_planet.jpeg"
            class="logo-img"
          />
        </div>
      </router-link>
    </div>

    <!-- NAV -->
    <s-nav
      :class="
        isMenu
          ? 'sidebar__nav flex__fluid flex--middle nav-open'
          : 'layout--auth sidebar__nav flex__fluid flex--middle'
      "
      align="center"
      :key="$route.path"
    >
      <s-button
        label="Dashboard"
        icon="vmdi-view-dashboard"
        :to="{ name: 'dashboard' }"
      />
      <s-button
        label="Logout"
        :to="{ name: 'logout' }"
        icon="vmdi-logout-variant"
      />
    </s-nav>
  </div>
</template>

<script>
export default {
  props: {
    collapse: {
      type: Boolean
    },
    isMenu: {
      type: Boolean
    }
  },
  components: {},
  computed: {}
};
</script>

<style lang="scss" scoped>
.toggle-menu {
  display: none;
}
.logo {
  flex: 0 0 200px;
}
.user {
  text-decoration: none;
}

.sidebar__nav {
  overflow: auto;
}
.sidebar__action {
  margin-left: auto;
  &::v-deep {
    .button {
      flex-direction: row !important;
      &__icon {
        margin-bottom: 0px !important;
      }
      &__label {
        margin-left: 10px !important;
      }
    }
  }
  .calculator-link {
    &::v-deep {
      .button {
        --button--color: #{--color(secondary)};
      }
    }
  }
}
::v-deep {
  .button {
    --button--color: #{--color(grey)};
    --button--hover-color: #{--color(light)};
    flex-direction: column;
    &__icon {
      margin-bottom: 5px;
    }
    &__label {
      margin: 0px !important;
    }
  }
  .button--active {
    --button--bg: #{--color(primary)};
    --button--color: #{--color(light)};
    --button--hover-bg: #{--color(primary, dark)};
    --button--hover-color: #{--color(light)};
  }
}
@media (max-width: 1100px) {
  .toggle-menu {
    display: block;
  }
  .nav {
    position: fixed;
    top: 92px;
    left: 0px;
    display: block;
    width: 320px;
    bottom: 0;
    background-color: var(--color--grey--lightest);
    z-index: 10;
    transition: all 0.3s;
    transform: translateX(-320px);
    padding: 20px;
    > .nav__item {
      & + .nav__item {
        margin-top: 10px;
      }
    }
    &.nav-open {
      transform: translateX(0);
    }
  }
  ::v-deep {
    .nav__link {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 10px;
      .button__icon {
        margin-bottom: 0px;
      }
      .button__label {
        flex: 1;
        margin-left: 10px !important;
      }
    }
  }
}
@media (max-width: 1100px) {
  .logo {
    padding-left: 0px;
  }
}
.logo-img {
  border-radius: 3px;
}
</style>
