import Vue from "vue";

Vue.component("MasterHeader", require("@/components/MasterHeader").default);
Vue.component("Tree", require("@/views/tree/Tree").default);

Vue.mixin({
    data() {
        return {
            IS_DEV: process.env.NODE_ENV !== "production",
        };
    },
});