<template>
  <div class="h-100">
    <portal to="header">
      <s-button
        size="sm"
        color="primary"
        theme="primary"
        icon="vmdi-plus"
        label="Add New"
        @click.native="openModal"
      />
    </portal>
    <sp-list
      ref="list"
      :attrs="columns"
      endpoint="v1/xmls"
      :actions="['refresh', 'settings']"
      class="h-100 p-4"
      title="XML"
    >
      <sp-list-table>
        <template #file="{ item }">
          <a :href="item.path" target="_blank" class="text-primary text-bold"
            >File</a
          >
        </template>
        <template #csv_file="{ item }">
          <a v-if="item.csv_path" :href="item.csv_path" target="_blank" class="text-primary text-bold"
            >File</a
          >
          <span v-else>-</span>
        </template>
        <!-- <template #tree_view="{item}">
          <s-button
            size="sm"
            color="primary"
            icon="vmdi-file-tree"
            @click.native="openTree(item)"
          />
        </template> -->
        <template #actions="{ item }">
          <div class="flex flex--nowrap">
            <s-button
              size="sm"
              color="primary"
              icon="vmdi-file-excel"
              class="mr-1"
              label="XML to CSV"
              @click.native="openTree(item)"
              :loader="loader"
            />
            <s-button
              size="sm"
              color="info"
              icon="vmdi-eye"
              class="mr-1"
              @click.native="openDetails(item)"
              :loader="loader"
            />
            <s-button
              size="sm"
              color="danger"
              icon="vmdi-delete"
              @click.native="deleteHandler(item)"
              :loader="loader"
            />
          </div>
        </template>
      </sp-list-table>
    </sp-list>
    <s-modal id="file-insert-modal" title="File Upload" overlay="dark">
      <div class="flex bg-light flex--column flex--nowrap h-100 radius-4">
        <div class="flex__fit bg-light bb-lightest px-3 py-2">
          <div class="flex flex--middle">
            <h4 class="flex__fluid">Upload File</h4>
            <div class="flex__fit">
              <s-button
                @click.native="closeModal()"
                size="sm"
                shape="circle"
                theme="muted"
                color="grey"
                icon="vmdi-close"
              ></s-button>
            </div>
          </div>
        </div>
        <div class="content flex__fluid bg-light">
          <item-form
            :item="false"
            :fields="fields"
            :save="save"
            class="p-4"
            :footer="true"
            :breadcrumb="false"
            :redirect="false"
            ref="item"
            :notify="true"
            :gap="3"
          >
            <template #default="{ form }">
              <s-field-validate
                vid="file"
                name="File"
                label="File *"
                rules="required"
              >
                <s-file
                  align="left"
                  label="Upload"
                  color="primary"
                  v-model="form.xml_file"
                  class="custom-upload"
                  accept=".xml"
                />
              </s-field-validate>
            </template>
            <template #footer="{ saveItem, saving }" :class="['']">
              <div class="flex flex--right flex--fit">
                <s-button
                  color="primary"
                  label="Save"
                  @click.native="saveItem"
                  :loader="saving"
                />
              </div>
            </template>
          </item-form>
        </div>
      </div>
    </s-modal>
    <Details :currentFile="currentFile" />
    <TreeView :id="currentId" />
  </div>
</template>
<script>
import { saveXML, deleteXML } from "@/api/auth";
// import { serialize } from "object-to-formdata";
export default {
  data() {
    return {
      fields: ["xml_file"],
      loader: false,
      currentFile: {},
      currentId: null
    };
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Details: require("./Details").default,
    TreeView: require("./TreeModal").default
  },
  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        {
          name: "name"
        },
        // {
        //   name: "tree_view",
        //   fix: true,
        // },
        {
          name: "file",
          label: "XML file",
          fix: true
        },
        {
          name: "csv_file",
          label: "CSV file",
          fix: true
        },
        {
          name: "actions",
          fix: true
        }
      ];
    }
  },
  methods: {
    openModal() {
      this.$root.$emit("shilp-modal-open", "file-insert-modal");
    },
    openTree(item) {
      this.currentId = item.id;
      this.$root.$emit("shilp-modal-open", "tree-details-modal");
    },
    closeModal() {
      this.$root.$emit("shilp-modal-close", "file-insert-modal");
    },
    async save(id, data) {
      let formData = new FormData();
      formData.append("xml_file", data?.xml_file?.[0]);
      return saveXML(formData).then(res => {
        this.$refs.list.refresh();
        this.$root.$emit("shilp-modal-close", "file-insert-modal");
      });
    },
    openDetails(item) {
      this.currentFile = item;
      this.$root.$emit("shilp-modal-open", "file-details-modal");
    },
    deleteHandler(item) {
      let result = window.confirm("Are you sure!");
      if (!result) {
        return;
      }
      this.loader = true;
      deleteXML(item)
        .then(res => {
          this.$refs.list.refresh();
          this.loader = false;
        })
        .catch(err => {
          console.log(err);
          this.loader = false;
          this.$shilp.notify({
            type: "danger",
            title: "Something went wrong!!",
            message: err?.message
          });
        });
    }
  }
};
</script>
<style lang="scss">
.content {
  overflow: auto;
}
</style>
