import Vue from "vue";
import Vuex from "vuex";
import hydrate from "./modules/hydrate";
import root from "./root";
import { cloneDeep } from "lodash-es";

Vue.use(Vuex);

const store = new Vuex.Store({
    ...root,
    modules: {
        hydrate,
    },
    // plugins: [vuexLocal.plugin],
});

const initialState = cloneDeep(store.state);

//Resets the store when user logs out
const resetStore = () => {
    store.replaceState(cloneDeep(initialState));
};


export default store;
export { resetStore };