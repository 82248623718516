import Vue from "vue";
import App from "./App.vue";
import router from "./router/";
import store, { resetStore } from "./store/";
import "@/plugins";
import "./global";
Vue.config.productionTip = false;

window.Vue = new Vue({
    router,
    store,
    render: (h) => h(App),
    created() {
        this.$root.$on("logout", () => {
            resetStore();
        });
    },
}).$mount("#app");