<template>
  <s-modal id="tree-details-modal" overlay="dark">
    <div class="flex bg-light flex--column flex--nowrap h-100 radius-4">
      <div class="flex__fit bg-light bb-lightest px-3 py-2">
        <div class="flex flex--middle">
          <h4 class="flex__fluid">Select Fields</h4>
          <div class="flex__fit">
            <s-button
              @click.native="closeModal()"
              size="sm"
              shape="circle"
              theme="muted"
              color="grey"
              icon="vmdi-close"
            ></s-button>
          </div>
        </div>
      </div>
      <request :get="get" #default="{ res }" ref="treeDetails">
        <div class="content flex__fluid bg-light p-3 h-300">
          <Tree
            :checked="checked"
            :fields="res"
            @add="onAdd($event)"
            @remove="onRemove($event)"
          />
        </div>
        <div class="flex__fit modal__footer">
          <s-button
            label="Download Now"
            icon="vmdi-file-excel"
            color="primary"
            theme="muted"
            :loader="isLoader"
            :disabled="checked.length < 1"
            @click.native="downloadFile(res)"
          >
          </s-button>
        </div>
      </request>
    </div>
  </s-modal>
</template>
<script>
import { getXMLFeild } from "@/api/auth";
import { downloadCSV } from "@/api/auth";

export default {
  props: {
    id: [Number, String]
  },
  components: {
    Request: require("@/components/Request").default,
    Tree: require("../tree/Tree").default
  },
  data() {
    return {
      checked: [],
      isLoader: false
    };
  },
  methods: {
    closeModal() {
      this.checked = [];
      this.$root.$emit("shilp-modal-close", "tree-details-modal");
    },
    get() {
      return getXMLFeild().then(res => res);
    },
    downloadFile(node) {
      this.isLoader = true;
      const parentKeys = node
        .filter(node => node.children)
        .map(node => node.key);

      const childKeys = this.checked.filter(key => !parentKeys.includes(key));
      // return;

      let data = {
        xml_fields: childKeys
      };
      let currentId = this.id;
      return downloadCSV(data, currentId).then(res => {
        this.isLoader = false;
        window.open(res.csv_path);
        this.checked = [];
        this.$root.$emit("shilp-modal-close", "tree-details-modal");

        return res;
      });
    },
    onAdd(keys) {
      this.checked = Array.from(new Set([...this.checked, ...keys]));
    },
    onRemove(keys) {
      this.checked = this.checked.filter(key => !keys.includes(key));
    }
  }
};
</script>
<style lang="scss">
.h-300 {
  height: 300px;
  overflow: scroll;
}
.modal__footer {
  padding: 10px 20px;
  border-top: 1px solid #eaeaea;
}
</style>
