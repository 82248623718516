<template>
  <div
    :key="$route.name"
    class="flex flex--middle flex--nowrap bb-lightest px-2 py-1 top-bar bg-natural-lightest"
  >
    <s-button-group
      shape="square"
      align="left"
      class="flex__fit toggle-sub-menu"
      size="lg"
      v-if="isSub"
    >
      <s-button
        icon="vmdi-menu"
        @click.native="$emit('isSubMenu', !isSubMenu)"
      />
    </s-button-group>
    <s-breadcrumbs
      ref="breadcrumbs"
      class="flex__fit breadcrumbs"
    ></s-breadcrumbs>
    <div class="flex__fit ml-auto content-box">
      <portal-target name="header"></portal-target>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "master-header",
  props: {
    title: String,
    isSub: {
      type: Boolean,
    },
    isSubMenu: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters([""]),
  },
  mounted() {
    this.$refs.breadcrumbs.$forceUpdate();
  },
};
</script>
<style lang="scss">
.toggle-sub-menu {
  display: none;
}
@media (max-width: 1100px) {
  .toggle-sub-menu {
    display: block;
    margin-left: 3px;
  }
}
@media (max-width: 768px) {
  .top-bar {
    flex-wrap: wrap !important;
  }
}
</style>
