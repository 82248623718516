<template>
  <s-layout full-height>
    <template #default>
      <XMLListing />
    </template>
  </s-layout>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["user"]),
  },
  components: {
    XMLListing: require("./List").default,
  },
  created() {},
  methods: {},
};
</script>
