<template>
  <!-- HYDRATED -->
  <s-layout
    :class="isMenu ? 'layout--auth is-open--menu' : 'layout--auth'"
    v-if="hydrated"
    full-height
  >
    <template #top>
      <master-sidebar
        @collapse="collapse = $event"
        @isMenu="isMenu = $event"
        :isMenu="isMenu"
        :collapse="collapse"
      />
      <master-header />
    </template>
    <template #default>
      <div class="mt-5 pt-2">
        <router-view slot="main" />
      </div>
    </template>
  </s-layout>

  <!-- HYDRATING -->
  <div v-else class="loading">
    <div class="logo">
      <img src="@/img/logo_planet.jpeg" />
    </div>
    <div class="mt-3 p-3" v-shilp-loader="true"></div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { setToken } from "@/plugins/axios";
import { getProfile } from "@/api/auth";

export default {
  name: "layout",
  components: {
    MasterSidebar: require("@/components/MasterSidebar").default
  },
  data() {
    return {
      collapse: true,
      isMenu: true
    };
  },
  computed: {
    ...mapGetters(["hydrating", "hydrated"]),
    ...mapState({
      user: state => state.user
    })
  },

  created() {
    this.check();
    this.$root.$on("rehydrate", () => {
      this.check();
    });
  },
  methods: {
    ...mapActions(["hydrate"]),
    async check() {
      const token = window.localStorage.getItem("pt-token");
      if (token) {
        setToken(token);
        try {
          const res = await getProfile();
          this.hydrate(res);
          // this.$router.push({ name: "dashboard" });
        } catch (err) {
          console.error(err);
          this.$shilp.notify({
            type: "danger",
            title: "Hydration Failed",
            message:
              "Kindly clear cache & try again. If problem persist, please contact us."
          });
        }
      } else {
        this.$router.push({ name: "login" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    width: 100px;
    height: 100px;
    border: 1px solid --color(grey, lighter);
    border-radius: --radius(4);
    padding: --space(3);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
.layout--auth {
  grid-template-rows: var(--layout--top-size, minmax(0, max-content)) 1fr var(
      --layout--bottom-size,
      minmax(0, max-content)
    );
  margin-top: 92px;
}
::v-deep {
  .layout__top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    .sidebar {
      padding: 0px 10px;
    }
  }
}
</style>
