<template>
  <auth image="login" :error="error">
    <validation-observer v-slot="{ invalid }">
      <form @submit.prevent="login()" class="field-group g-2">
        <s-field-validate name="email" rules="required|email">
          <s-textbox
            ref="email"
            v-model="form.email"
            type="email"
            class="field--xl field--info px-3 text-sm"
            placeholder="Your Email"
          />
        </s-field-validate>

        <s-field name="password" rules="required">
          <s-textbox
            v-model="form.password"
            type="password"
            class="field--xl field--info px-3 text-sm"
            placeholder="Password"
          />
        </s-field>

        <s-button
          :disabled="invalid"
          :loader="loading"
          type="submit"
          align="center"
          color="primary"
          size="lg"
          fluid
          label="login"
        />
      </form>
    </validation-observer>
    <template #footer>
      <p class="text-primary text-bold mb-1 text-md">PLANET TELECOM</p>
      <p class="footer-text lh-4">
        13235 146 ST NW, Edmonton, AB T5L 4S8
      </p>
      <p class="footer-text lh-4">
        <a href="tel:780 409 9000"> Phone: 780.409.9000</a>
      </p>
      <p class="footer-text lh-4">
        <a href="tel:+1 866 296 7771">Toll Free: 1.866.296.7771</a>
      </p>
      <p class="footer-text lh-4">
        <a href="mailto:Support@PlanetTelecom.com">
          Email: Support@PlanetTelecom.com
        </a>
      </p>
    </template>
  </auth>
</template>

<script>
import { login } from "@/api/auth";
import { setToken } from "@/plugins/axios";
import { mapActions } from "vuex";

export default {
  name: "login",
  components: {
    Auth: require("@/components/Auth").default,
  },
  data() {
    return {
      error: null,
      loading: false,
      form: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {
    this.$refs.email.$el.focus();
  },
  methods: {
    async login() {
      this.error = null;
      this.loading = true;
      login(this.form)
        .then((res) => {
          localStorage.setItem("pt-token", res.token);
          setToken(res.token);
          this.$router.push("/");
        })
        .catch((err) => {
          this.error = err.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
