<template>
  <div class="field-list">
    <div
      class="tree"
      v-for="(node, index) in fields"
      :key="index + '_' + node.key"
    >
      <ul class="tree-list">
        <li class="node-tree">
          <div class="custom-checkbox">
            <input
              type="checkbox"
              :value="node.key"
              :checked="checked.includes(node.key)"
              @input.prevent="toggle(node, node.key)"
              :id="'checkbox-' + node.key"
            />
            <label :for="'checkbox-' + node.key">
              <s-icon
                :size="20"
                class="text-light is-check"
                name="vmdi-check-circle"
              />
              <s-icon
                :size="20"
                class="text-grey-lighter is-default"
                name="vmdi-check-circle-outline"
              />
              <span class="ml-2">{{ node.title }}</span>
            </label>
            <!-- <span class="label">{{ node.title }}</span> -->
          </div>
          <ul v-if="node.children && node.children.length">
            <Tree
              :checked="checked"
              :fields="node.children"
              @add="$emit('add', $event)"
              @remove="$emit('remove', $event)"
            />
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fields: Array,
    checked: Array
  },
  methods: {
    toggle(node) {
      const keys = this.findChildrenKeys(node);
      if (this.checked.includes(node.key)) {
        this.$emit("remove", keys);
      } else {
        this.$emit("add", keys);
      }
    },
    findChildrenKeys(node, keys = []) {
      keys.push(node.key);
      if (node.children) {
        node.children.forEach(node => {
          this.findChildrenKeys(node, keys);
        });
      }
      return keys;
    }
  }
};
</script>

<style lang="scss">
.tree-list {
  padding: 0px;
}
.tree-list ul {
  margin: 0px;
  list-style: none;
}
ul {
  list-style: none;
}
.custom-checkbox {
  margin: 5px;
  display: flex;
  position: relative;
  .is-check {
    display: none;
  }
  .is-default {
    display: block;
  }
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0;
    &:checked + label {
      background-color: #f7974b;
      color: #fff;
      border-color: #f7974b;
      .is-check {
        display: block;
      }
      .is-default {
        display: none;
      }
    }
  }
  label {
    padding: 5px 10px;
    border: 1px solid #eaeaea;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

// .field-list {
//   display: flex;
//   flex-wrap: wrap;
// }
</style>
