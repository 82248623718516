import Vue from "vue";
import "./vee-validate";


/**
 * Axios
 */
import axios from "./axios";
Vue.prototype.$axios = axios;

import draggable from "vuedraggable";
Vue.component("draggable", draggable);

/**
 * Vue Portal
 */
import PortalVue from "portal-vue";
Vue.use(PortalVue);

/**
 * VCalendar
 */
import VCalendar from "v-calendar";
Vue.use(VCalendar);

/**
 * V Tooltip
 */
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)

/**
 * Datepicker Chart
 */
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

Vue.use(DatePicker);
Vue.component("date-picker", DatePicker);

/**
 * Click Outside
 */
import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);

/*
* Vue Select
*/

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';


/**
 *
 */
import SpListConfig from "./sp-list-plugin";

import ShilpVue, {
    ListPlugin,
    ListTable,
    FieldValidate,
    FormValidate,
    ListMasnory,
    ListGrid
} from "shilp-vue";

Vue.use(ShilpVue, {
    vueMaterialDesignIcons: require("@/icons").default
});

Vue.use(ListPlugin, SpListConfig);
Vue.component("SFieldValidate", FieldValidate);
Vue.component("SFormValidate", FormValidate);
Vue.component("SpListTable", ListTable);
Vue.component("SpListMasnory", ListMasnory);
Vue.component("SpListGrid", ListGrid);
require("shilp-vue/dist/shilp-vue.css");
