var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('portal',{attrs:{"to":"header"}},[_c('s-button',{attrs:{"size":"sm","color":"primary","theme":"primary","icon":"vmdi-plus","label":"Add New"},nativeOn:{"click":function($event){return _vm.openModal.apply(null, arguments)}}})],1),_c('sp-list',{ref:"list",staticClass:"h-100 p-4",attrs:{"attrs":_vm.columns,"endpoint":"v1/xmls","actions":['refresh', 'settings'],"title":"XML"}},[_c('sp-list-table',{scopedSlots:_vm._u([{key:"file",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-primary text-bold",attrs:{"href":item.path,"target":"_blank"}},[_vm._v("File")])]}},{key:"csv_file",fn:function(ref){
var item = ref.item;
return [(item.csv_path)?_c('a',{staticClass:"text-primary text-bold",attrs:{"href":item.csv_path,"target":"_blank"}},[_vm._v("File")]):_c('span',[_vm._v("-")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex flex--nowrap"},[_c('s-button',{staticClass:"mr-1",attrs:{"size":"sm","color":"primary","icon":"vmdi-file-excel","label":"XML to CSV","loader":_vm.loader},nativeOn:{"click":function($event){return _vm.openTree(item)}}}),_c('s-button',{staticClass:"mr-1",attrs:{"size":"sm","color":"info","icon":"vmdi-eye","loader":_vm.loader},nativeOn:{"click":function($event){return _vm.openDetails(item)}}}),_c('s-button',{attrs:{"size":"sm","color":"danger","icon":"vmdi-delete","loader":_vm.loader},nativeOn:{"click":function($event){return _vm.deleteHandler(item)}}})],1)]}}])})],1),_c('s-modal',{attrs:{"id":"file-insert-modal","title":"File Upload","overlay":"dark"}},[_c('div',{staticClass:"flex bg-light flex--column flex--nowrap h-100 radius-4"},[_c('div',{staticClass:"flex__fit bg-light bb-lightest px-3 py-2"},[_c('div',{staticClass:"flex flex--middle"},[_c('h4',{staticClass:"flex__fluid"},[_vm._v("Upload File")]),_c('div',{staticClass:"flex__fit"},[_c('s-button',{attrs:{"size":"sm","shape":"circle","theme":"muted","color":"grey","icon":"vmdi-close"},nativeOn:{"click":function($event){return _vm.closeModal()}}})],1)])]),_c('div',{staticClass:"content flex__fluid bg-light"},[_c('item-form',{ref:"item",staticClass:"p-4",attrs:{"item":false,"fields":_vm.fields,"save":_vm.save,"footer":true,"breadcrumb":false,"redirect":false,"notify":true,"gap":3},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var form = ref.form;
return [_c('s-field-validate',{attrs:{"vid":"file","name":"File","label":"File *","rules":"required"}},[_c('s-file',{staticClass:"custom-upload",attrs:{"align":"left","label":"Upload","color":"primary","accept":".xml"},model:{value:(form.xml_file),callback:function ($$v) {_vm.$set(form, "xml_file", $$v)},expression:"form.xml_file"}})],1)]}},{key:"footer",fn:function(ref){
var saveItem = ref.saveItem;
var saving = ref.saving;
return [_c('div',{staticClass:"flex flex--right flex--fit"},[_c('s-button',{attrs:{"color":"primary","label":"Save","loader":saving},nativeOn:{"click":function($event){return saveItem.apply(null, arguments)}}})],1)]}}])})],1)])]),_c('Details',{attrs:{"currentFile":_vm.currentFile}}),_c('TreeView',{attrs:{"id":_vm.currentId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }