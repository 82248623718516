import Vue from "vue";
import * as VeeValidate from "vee-validate";
import { ValidationProvider, ValidationObserver, extend, Validator } from "vee-validate";
import { required, email, min, numeric, digits, required_if, min_value, alpha_num } from "vee-validate/dist/rules";
const rules = { required, email, min, numeric, digits, min_value, required_if, alpha_num };

for (let rule in rules) {
    extend(rule, {
        ...rules[rule]
    });
}

Vue.use(VeeValidate);
extend("url", {
    validate: value => {
        const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
        return value.match(new RegExp(regex));
    }
});

extend("selectDifferent", {
    params: ["target"],
    validate: (value, { target }) => {
        if (value === target) {
            return "The secondary employee id and employee id must be different.";
        } else {
            return true;
        }
    }
});

extend("alpha_special", {
    validate: (value) => {
        const regex = /^([^0-9]*)$/g;
        return value.match(new RegExp(regex));
    },
});

extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match'
});

extend("strong_password", {
    validate: (value) => {
        // const regex = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[0-9]){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/g;
        const regex = /^.*(?=.{10,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[0-9]){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/g;
        return value.match(new RegExp(regex));
    },
    message: `Password need to contain at least one of the following:  one upper character, one lower character, at least one number,one special character and 10 minimum character`,
});


Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("url", {
    validate: value => {
        const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
        return value.match(new RegExp(regex));
    }
});

extend("selectDifferent", {
    params: ["target"],
    validate: (value, { target }) => {
        if (value === target) {
            return "The secondary employee id and employee id must be different.";
        } else {
            return true;
        }
    }
});

extend("alpha_special", {
    validate: (value) => {
        const regex = /^([^0-9]*)$/g;
        return value.match(new RegExp(regex));
    },
});

extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match'
});

extend("strong_password", {
    validate: (value) => {
        // const regex = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[0-9]){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/g;
        const regex = /^.*(?=.{10,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[0-9]){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/g;
        return value.match(new RegExp(regex));
    },
    message: `Password need to contain at least one of the following:  one upper character, one lower character, at least one number,one special character and 10 minimum character`,
});


Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);