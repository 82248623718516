import { SET_USER } from "./mutation-types";
import { SET_APP } from "./mutation-types";

const state = {
    user: null,
    app: null,
};

const getters = {
    user: state => {
        return (state.user) || null;
    },
    userRoles: state => {
        return (state.user && state.user.roles[0].name) || null;
    },
};

const mutations = {
    [SET_USER](state, data) {
        state.user = data;
    },
    [SET_APP](state, data) {
        state.app = data;
    },
};

const actions = {
    setUser({ commit }, data) {
        commit(SET_USER, data);
    },
    setApp({ commit }, data) {
        commit(SET_APP, data);
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};
