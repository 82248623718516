<template>
  <div class="page-header">
    <h3 class="text-primary">{{ title }}</h3>
    <div class="text-grey-dark">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String
  }
};
</script>