<template>
  <div class="auth">
    <div class="auth__sidebar">
      <!-- <img class src="@/img/pattern.svg" alt /> -->
      <img class src="@/img/liquid-cheese.svg" alt />
    </div>
    <div class="auth__content">
      <div class="login-box" :class="loginModify">
        <div class="login-box__form">
          <div class="login-box__header">
            <div class="logo">
              <img src="@/img/logo_planet.jpeg" alt />
            </div>
          </div>
          <item-form-error class="mb-2" :data="error" />
          <slot></slot>
        </div>
        <div class="login-box__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "auth",
  props: {
    image: {
      type: String,
      default: "login"
    },
    error: Object,
    loginModify: String
  },
  components: {
    ItemFormError: require("@/components/Error").default
  }
};
</script>

<style lang="scss" scoped>
.auth {
  height: 100vh;
  overflow: auto;
  display: flex;
  &__sidebar {
    flex: 0 0 310px;
    font-size: 0px;
    background-color: --color("secondary");

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right bottom;
    }
  }
  &__content {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: --space(4) --space(5);
  }
}
.logo {
  text-align: center;
  img {
    width: 40%;
  }
}
.login-box {
  width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  &__header {
    margin-bottom: --space(3);
    padding-bottom: --space(3);
  }
  &__form {
    max-width: 320px;
    margin: 0 auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__footer {
    margin-top: auto;
    text-align: center;
  }
}

.wrap {
  height: 100%;
  align-items: stretch;
}

.footer-text {
  font-size: 14px;
  text-align: center;
  color: --color("grey");
  font-family: --text("content");
  a {
    display: block;
    color: --color("info");
  }
}
@media (max-width: 1024px) {
  .auth {
    display: block;
    &__sidebar {
      display: none;
    }
    &__content {
      height: 100%;
      padding: var(--space--2);
    }
  }
}
</style>
