export default {
    "vmdi-view-dashboard": require("@icon/ViewDashboard").default,
    "vmdi-account-box": require("@icon/AccountBox").default,
    "vmdi-semantic-web": require("@icon/SemanticWeb").default,
    "vmdi-face-agent": require("@icon/FaceAgent").default,
    "vmdi-file-account": require("@icon/FileAccount").default,
    "vmdi-file-document": require("@icon/FileDocument").default,
    "vmdi-account-circle": require("@icon/AccountCircle").default,
    "vmdi-calculator": require("@icon/Calculator").default,
    "vmdi-google-translate": require("@icon/GoogleTranslate").default,
    "vmdi-account-supervisor": require("@icon/AccountSupervisor").default,
    "vmdi-eye": require("@icon/Eye").default,
    "vmdi-delete": require("@icon/Delete").default,
    "vmdi-close": require("@icon/Close").default,
    "vmdi-file-tree": require("@icon/FileTree").default,
    "vmdi-logout-variant": require("@icon/LogoutVariant").default,
    "vmdi-account-switch": require("@icon/AccountSwitch").default,
    "vmdi-bank": require("@icon/Bank").default,
    "vmdi-cash": require("@icon/Cash").default,
    "vmdi-circle": require("@icon/Circle").default,
    "vmdi-download": require("@icon/Download").default,
    "vmdi-email": require("@icon/Email").default,
    "vmdi-at": require("@icon/At").default,
    "vmdi-lock-open": require("@icon/LockOpen").default,
    "vmdi-lock": require("@icon/Lock").default,
    "vmdi-check": require("@icon/Check").default,
    "vmdi-plus": require("@icon/Plus").default,
    "vmdi-clipboard-text": require("@icon/ClipboardText").default,
    "vmdi-dialpad": require("@icon/Dialpad").default,
    // "vmdi-currency-usd-circle": require("@icon/CurrencyUsdCircle").default,
    "vmdi-basket-plus": require("@icon/BasketPlus").default,
    "vmdi-account-edit": require("@icon/AccountEdit").default,
    "vmdi-google-translate": require("@icon/GoogleTranslate").default,
    "vmdi-square-edit-outline": require("@icon/SquareEditOutline").default,
    "vmdi-filter-remove": require("@icon/FilterRemove").default,
    "vmdi-menu": require("@icon/Menu").default,
    "vmdi-file-excel": require("@icon/FileExcel").default,
    "vmdi-check-circle": require("@icon/CheckCircle").default,
    "vmdi-check-circle-outline": require("@icon/CheckCircleOutline").default,
    "vmdi-dots-vertical": require("@icon/DotsVertical").default,
};