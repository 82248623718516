<template>
  <div class="h-100" id="app">
    <div v-if="loader" class="loader"></div>
    <div v-else class="h-100">
      <s-copy />
      <s-confirm />
      <s-notification-container top="50px" right="50px" />
      <router-view v-if="ready" />
      <s-modal-container />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
      loader: false
    };
  },
  created() {
    this.loader = true;
    if (this.$route.meta.public) {
      this.loader = false;
    } else {
      this.loader = false;
    }
  },
  mounted() {
    this.ready = true;
  }
};
</script>
<style lang="scss">
@import "@/scss/style.scss";
@import "shilp/scss/styles.scss";
</style>
