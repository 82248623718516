<template>
  <auth image="login" loginModify="login-box--forgot" :error="error">
    <!-- OTP SENT -->
    <s-alert
      v-if="codeSent && !reset"
      color="success"
      theme="muted"
      fluid
      class="mb-3 gx-2 gy-1"
      :title="$t('email_sent')"
      icon="vmdi-check-circle"
    >
      <p>
        <!-- We've sent you an email with OTP to generate new password. -->
        {{ successMessage }}
      </p>
    </s-alert>

    <!-- RESET DONE -->
    <s-alert
      v-if="reset"
      color="success"
      theme="muted"
      fluid
      class="mb-3 gx-2 gy-1"
      :title="$t('password_changed')"
      icon="vmdi-check-circle"
    >
      <p>
        <router-link :to="{ name: 'login' }">Login now</router-link>{{ " " }}
        {{ successMessage }}
      </p>
    </s-alert>

    <validation-observer v-if="!reset" v-slot="{ invalid }">
      <form
        @submit.prevent="codeSent ? resetPassword() : sendCode()"
        class="field-group g-2"
      >
        <s-field-validate
          :name="$t('email')"
          rules="required|email"
          before-icon="vmdi-at"
        >
          <s-textbox
            ref="email"
            v-model="form.email"
            type="email"
            class="field--md field--info px-3 text-sm"
            :placeholder="$t('email')"
            :readonly="codeSent"
          />
        </s-field-validate>

        <template v-if="codeSent">
          <s-field-validate
            :name="$t('otp')"
            rules="required"
            before-icon="vmdi-dialpad"
          >
            <s-textbox
              v-model="form.otp"
              type="number"
              class="field--md field--info px-3 text-sm"
              :placeholder="$t('otp')"
            />
          </s-field-validate>

          <s-field-validate
            v-if="codeSent"
            :label="$t('new_password')"
            vid="password"
            name="password"
            rules="required|strong_password"
            before-icon="vmdi-lock"
            :password-toggle="true"
          >
            <s-textbox
              v-model="form.password"
              type="password"
              class="field--md field--info px-3 text-sm"
              :placeholder="$t('new_password')"
            />
          </s-field-validate>
          <!-- <s-field-validate
            v-if="codeSent"
            :label="$t('confirm_password')"
            rules="required|password:@password"
            before-icon="vmdi-lock"
            :password-toggle="true"
          >
            <s-textbox
              v-model="form.password_confirmation"
              class="field--md field--info px-3 text-sm"
              type="password"
              :placeholder="$t('password_confirmation')"
            />
          </s-field-validate> -->
        </template>

        <s-button
          :disabled="invalid"
          :loader="loading"
          type="submit"
          align="right"
          icon="vmdi-lock-open"
          color="primary"
          size="lg"
          fluid
          :label="$t('reset_password')"
        />
      </form>
    </validation-observer>

    <div class="mt-3 flex flex--between flex--fluid">
      <router-link class="flex__fit" to="/"> {{ $t("login") }}? </router-link>
    </div>
  </auth>
</template>

<script>
import { forgot, reset } from "@/api/auth";
export default {
  name: "login",

  components: {
    Auth: require("@/components/Auth").default
    // ItemForm: require("@/components/ItemForm").default
  },

  data() {
    return {
      error: null,
      loading: false,
      codeSent: false,
      reset: false,
      successMessage: null,
      form: {
        email: null,
        password: null,
        otp: null
      }
    };
  },

  mounted() {
    this.$refs.email.$el.focus();
  },

  methods: {
    async sendCode() {
      this.codeSent = false;
      this.error = null;
      try {
        this.loading = true;
        const res = await forgot({ email: this.form.email });
        this.codeSent = true;
        this.successMessage = res.message;
      } catch (err) {
        this.error = err.data;
      }
      this.loading = false;
    },

    async resetPassword() {
      this.error = null;
      this.reset = false;
      try {
        this.loading = true;
        const res = await reset(this.form);
        this.codeSent = false;
        this.reset = true;
        this.successMessage = res.message;
        this.$router.push({
          name: "login"
        });
      } catch (err) {
        this.error = err.data;
      }
      this.loading = false;
    }
  }
};
</script>
<style lang="scss">
.login-box--forgot {
  .login-box__form {
    max-width: 320px;
    width: 320px;
  }
  .logo {
    img {
      width: 80px;
    }
  }
}
</style>